import { Controller } from '@hotwired/stimulus';
import yall from 'yall-js';

export default class extends Controller {
  connect() {
    this.instance = yall({
      lazyClass: 'js-lazy',
      lazyBackgroundClass: 'js-lazy-bg',
      lazyBackgroundLoaded: '-is-loaded',
      observeChanges: true,
      idleLoadTimeout: 0,
      threshold: 200,
      noPolyfill: true,
      events: {
        load: (event) => {
          if (
            !event.target.classList.contains('lazy') &&
            event.target.nodeName === 'IMG'
          ) {
            const el = event.target.parentElement;
            el.classList.add('-is-loaded');
          }
        },
        error: {
          listener: (event) => {
            if (
              !event.target.classList.contains('lazy') &&
              event.target.nodeName === 'IMG'
            ) {
              event.target.classList.add('yall-error');
            }
          },
          options: {
            once: true
          }
        }
      }
    });
  }
}
